import { IBaseStructureAPI, IStructureStore, Structure } from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { ComponentsLoaderSymbol, IComponentsLoader } from '@wix/thunderbolt-components-loader'

const baseStructureAPI = (structureStore: IStructureStore, componentsLoader: IComponentsLoader): IBaseStructureAPI => {
	return {
		get: (compId) => structureStore.get(compId),
		subscribeToChanges: (partial) => structureStore.subscribeToChanges(partial),
		getEntireStore: () => structureStore.getEntireStore(),
		getContextIdOfCompId: (compId: string) => structureStore.getContextIdOfCompId(compId),
		replaceComponentInParent: (parentId, oldCompId, newCompId) => {
			const parent = structureStore.get(parentId)
			const components = [...parent.components]

			const compIndex = components.indexOf(oldCompId)
			if (compIndex > -1) {
				components[compIndex] = newCompId

				structureStore.update({
					[parentId]: { ...parent, components },
				})
			}
		},
		getPageWrapperComponentId: (pageId: string, contextId: string = pageId) =>
			pageId === contextId ? `${pageId}_wrapper` : contextId,
		addComponentToDynamicStructure: async (compId, compStructure, additionalComponents) => {
			const structure = {
				[compId]: compStructure,
				...additionalComponents,
			}
			structureStore.update(structure)
			await componentsLoader.loadComponents(structure)

			const { components } = structureStore.get('DYNAMIC_STRUCTURE_CONTAINER')
			structureStore.update({
				DYNAMIC_STRUCTURE_CONTAINER: {
					componentType: 'DynamicStructureContainer',
					components: [...components, compId],
				},
			})
		},
	}
}

export const BaseStructureAPI = withDependencies([Structure, ComponentsLoaderSymbol], baseStructureAPI)
